import React, { useState } from "react"
import { WishlistContext } from "./wishlistContext"

const WishlistProvider = ({ children }) => {
  const localState = JSON.parse(localStorage.getItem("wishlist"))
  const [wishlist, setWishlist] = useState(localState || [])
  const value = { wishlist, setWishlist }

  return (
    <WishlistContext.Provider value={value}>
      {children}
    </WishlistContext.Provider>
  )
}

export default WishlistProvider
