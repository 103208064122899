import merge from "lodash.merge"
import { theme } from "@multidigital/gatsby-theme-multi-blog/"
export default merge({}, theme, {
  space: [0, 4, 8, 16, 24, 48, 64, 96],
  colors: {
    text: "#131212",
    background: "#fff",
    primary: "#357731",
    secondary: "#131212",
  },
  fonts: {
    body: `"Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
    heading: `"Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 900,
  },
  lineHeights: {
    body: 1.3,
    heading: 1.125,
    normal: 1.5,
  },
  links: {
    nav: {
      px: 3,
      py: 2,
      fontSize: 1,
      textTransform: "uppercase",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "primary",
      "&:hover": {
        color: "primary",
      },
    },
  },
  text: {
    default: {
      color: "text",
      fontSize: 2,
      fontFamily: "body",
      lineHeight: "body",
    },
    blog: {
      fontSize: 2,
      fontFamily: "body",
      lineHeight: "normal",
    },
    caption: {
      fontSize: 1,
      fontFamily: "body",
    },
    heading: {
      fontSize: 5,
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    display: {
      fontSize: 6,
      color: "secondary",
      fontWeight: "heading",
      textTransform: "uppercase",
    },
    displaySmall: {
      fontSize: 6,
    },
    headingSmall: {
      fontSize: 4,
    },
    lead: {
      fontSize: 4,
    },
    leadSmall: {
      fontSize: 3,
    },
  },
  layout: {
    container: {
      maxWidth: ["455px", "580px", "740px", "960px", "1200px"],
      padding: ["1rem", "2rem"],
    },
    blog: {
      maxWidth: ["455px", "580px", "740px"],
      padding: ["0.25rem 1rem", "0.25rem 2rem"],
    },
  },
  breakpoints: ["475px", "640px", "768px", "1024px", "1280px"],
  styles: {
    a: {
      fontFamily: "body",
      color: "text",
      textDecoration: "none",
      "&:hover": {
        color: "primary",
      },
    },
  },
})
