// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-multidigital-gatsby-theme-multi-blog-src-templates-article-category-js": () => import("./../../../node_modules/@multidigital/gatsby-theme-multi-blog/src/templates/articleCategory.js" /* webpackChunkName: "component---node-modules-multidigital-gatsby-theme-multi-blog-src-templates-article-category-js" */),
  "component---node-modules-multidigital-gatsby-theme-multi-blog-src-templates-article-js": () => import("./../../../node_modules/@multidigital/gatsby-theme-multi-blog/src/templates/article.js" /* webpackChunkName: "component---node-modules-multidigital-gatsby-theme-multi-blog-src-templates-article-js" */),
  "component---node-modules-multidigital-gatsby-theme-multi-blog-src-templates-blog-js": () => import("./../../../node_modules/@multidigital/gatsby-theme-multi-blog/src/templates/blog.js" /* webpackChunkName: "component---node-modules-multidigital-gatsby-theme-multi-blog-src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */)
}

