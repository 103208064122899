/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"
import React, { useState } from "react"
import { ScreenClassProvider } from "react-grid-system"
import WishlistProvider from "./src/context/wishlistProvider"
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcE9sIkAAAAAOfwgvOX3qBYKUnFGFgomOWZTXL6">
    <WishlistProvider>
      <ScreenClassProvider fallbackScreenClass={"xs"} useOwnWidth>
        {element}
      </ScreenClassProvider>
    </WishlistProvider>
    </GoogleReCaptchaProvider>
    
  )
}
